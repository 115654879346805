<template>
    <v-container fluid>
  
      <!-- START DATATABLE -->
      <v-row class="pa-3">
          <v-data-table
              :headers="headers"
              :items="exportitems"
              :items-per-page="10"
              class="elevation-1"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :loading="loading"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
          >
              <template v-slot:top>
                  <v-toolbar flat color="white">
                      <v-toolbar-title>Exports</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                      />
                  </v-toolbar>
              </template>
              <template v-slot:top>
                <v-toolbar flat color="guacamole">
                    <v-toolbar-title>Export Configurations</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-1" color="grey" @click.prevent="createExport">
                        <span v-if="!loading">Create Export Configuration</span>
                        <v-progress-circular indeterminate v-else />
                    </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                    <v-btn
                        color="primary"
                        @click="doexport(item)"
                        class="ma-2"
                    >
                        Export
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="editexport(item)"
                        class="ma-2"
                    >
                        <v-icon small class="mr-2">
                        mdi-pencil
                        </v-icon> Edit
                    </v-btn>
                    <v-btn
                        color="red"
                        @click="getConfirm(item)"
                        class="ma-2"
                    >
                        <v-icon small class="mr-2">
                            mdi-delete
                        </v-icon> Delete
                    </v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item}">
                <td :colspan="headers.length">
                    <v-card class="ma-2">
                        <v-card-title>Applied Filters</v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item-content>
                                    <v-list-item-title><b>Country:</b> {{ item.data.country ? item.data.country : 'n/a' }}</v-list-item-title>
                                    <v-list-item-title><b>Region:</b> {{ item.data.region ? item.data.region : 'n/a' }}</v-list-item-title>
                                    <v-list-item-title><b>PoisonBaitFound:</b> {{ item.data.poisonBaitFound ? item.data.poisonBaitFound : 'n/a'}}</v-list-item-title>
                                    <v-list-item-title><b>AntipoisonDogUnitUsed:</b> {{ item.data.antipoisonDogUnitUsed ? item.data.antipoisonDogUnitUsed : 'n/a'}}</v-list-item-title>
                                    <v-list-item-title><b>OfficialComplaint:</b> {{ item.data.officialComplaint ? item.data.officialComplaint : 'n/a' }}</v-list-item-title>
                                    <v-list-item-title><b>caseTakenToCourt:</b> {{ item.data.caseTakenToCourt ? item.data.caseTakenToCourt : 'n/a' }}</v-list-item-title>
                                    <v-list-item-title><b>areaProtectionStatus:</b> {{ item.data.areaProtectionStatus ? item.data.areaProtectionStatus : 'n/a'}}</v-list-item-title>
                                    <v-list-item-title><b>TypeOfAnimal:</b> {{ item.data.TypeOfAnimal ? item.data.TypeOfAnimal : 'n/a'}}</v-list-item-title>
                                    <v-list-item-title><b>speciesfilter:</b> {{ item.data.speciesfilter ? item.data.speciesfilter : 'n/a'}}</v-list-item-title>
                                    <v-list-item-title><b>dateFrom:</b> {{ item.data.dateFrom ? item.data.dateFrom : 'n/a' }}</v-list-item-title>
                                    <v-list-item-title><b>dateTo:</b> {{ item.data.dateTo ? item.data.dateTo : 'n/a' }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </td>
              </template>
              <template v-slot:item.lastexport="{ item }">
                <span>{{  formatDate(item.lastexport) }}</span>
              </template>
              <template v-slot:item.modified="{ item }">
                <span>{{ formatDate(item.modified) }}</span>
              </template>
              <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
          </v-data-table>
      </v-row>
      <!-- END DATATABLE -->
  
  
      <!-- START - CONFIRM DELETE DIALOG -->
      <v-dialog
          v-model="deletedialog"
          persistent
          width="350"
          :ripple="foo"
          style="z-index: 1301"
      >
          <v-card>
              <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                  <v-toolbar-title>Confirm Export Delete</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                  <p>You are about to delete an <b>Export</b>. If you are sure,
                      type the following string:</p>
                  <p class="justify-center text-center" style="background-color: #e6e6e6">
                      <pre>{{ todelete }}</pre>
                  </p>
                  <p>in the text input below and press confirm.</p>
                  <v-form
                      v-model="valid"
                      ref="confirmform"
                      lazy-validation
                  > 
                      <v-text-field
                          v-model="deletecode"
                          :rules="confirmRule"
                      />
                  </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-spacer />
                  <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                  <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <!-- END - CONFIRM DELETE DIALOG -->
  
      <!-- <v-divider class="ma-4"/> -->

      <export-admin v-if="superadmin"/>
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { get, call } from "vuex-pathify";
  import AdminExportDataVue from '../components/AdminExportData.vue';

  // Datetime experiments
  import { DateTime } from "luxon";
  
  import exportapi from '@/services/ExportService'

  
  export default {
    components: {
        'export-admin': AdminExportDataVue
    },
    data() {
      return {
        // START Snackbar
        timeout: 5000,
        message: "hello",
        snackcolor: "success",
        // END Snackbar
        // START DATATABLE DATA
        expanded: [],
        singleExpand: false,
        // filters: {region: [], TypeOfAnimal: [], poisonBaitFound: [], autopsyAndMore: [], antipoisonDogUnitUsed: []},
        search: '',
        loading: false,
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        sortBy: 'entrycode',
        sortDesc: true,
        // END DATATABLE DATA
        // START DELETE DIALOG
        valid: false,
        deletedialog: false,
        foo: false,
        todelete: "",
        deluserid: "",
        deletecode: ""
        // END DELETE DIALOG
      };
    },
    computed: {
        ...get('newauth', [
          'userProfile@country',
          'userProfile@is_superuser'
        ]),
        exportitems: get('exportserv/getExports'),
        superadmin: get('newauth@userProfile.is_superuser'),
        headers () {
            return [
                { 
                    text: '',
                    value: 'data-table-expand'
                },
                {
                    text: 'Id',
                    value: 'id'
                },
                {
                    text: 'Filename',
                    value: 'name'
                },
                {
                    text: 'Description',
                    value: 'description'
                },
                {
                    text: 'Last exported',
                    value: 'lastexport'
                },
                {
                    text: 'Modified',
                    value: 'modified'
                },
                {
                    text: "Actions",
                    value: 'actions',
                    sortable: false
                },
            ]
        },
        confirmRule() {
          return [
              () => (this.todelete === this.deletecode) || 'Value does not match requested code',
              v => !!v || 'Fill value to confirm'
          ];
        },
    },
    methods: {
      fetchExports: call('exportserv/fetchExports'),
      dofetch: call('exportserv/fetchExport'),
      showMessage: call('messages/showMessage'),
      initialize () {
          this.fetchExports()
      },
      formatDate (date) {
        if (!date) return '-- not exported --'
        return DateTime.fromISO(date).toFormat('dd/LL/yyyy, HH:mm:ss')
      },
      showExpandedInfo (item) {
        return item.data
      },
      editexport (item) {
        this.$router.push({name: "View Export", params: {id: item.id}})
      },
      createExport() {
        this.$router.push({name:'New Export Configuration'})
      },
      // START CONFIRM DELETE METHODS
      getConfirm (item) {
          this.todelete = `delete ${item.id}`
          this.delentrycode = item.id
          this.deletedialog = true
      },
      delcancel () {
          this.$refs.confirmform.resetValidation()
          this.deletecode = ""
          this.todelete= ""
          this.delentrycode = ""
          this.deletedialog = false
      },
      delconfirm () {
          if (this.$refs.confirmform.validate()) {
              this.deletecode = ""
              // delete the user
              this.$store.dispatch('exportserv/delExport', this.delentrycode)
                  .then(console.log(true))
                  .catch(e => {
                      console.log(e)
                  })
          } else {
              this.foo = true
              this.showMessage({message: "The validation is not correct", color: "error"})
          }
          this.deletecode = ""
          this.delentrycode = ""
          this.$refs.confirmform.resetValidation()
          this.deletedialog = false
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      // END CONFIRM DELETE METHODS
      async doexport (item) {
        this.loading = true
        exportapi.getExportFile(item.id, this.$store.state.newauth.token).then((res) => {
          var FILE = window.URL.createObjectURL(
            new Blob([res.data],
            { type: 'application/vnd.ms-excel'}
          ));
          this.loading = false;
          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', `export-${item.name}-${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(docUrl);
          docUrl.click();
        }).catch((error) => {
          this.loading = false
          console.log(error)
        }).finally(() => {
            this.loading = false
            this.fetchExports()
            this.showMessage({ message: `Successfull export of "${item.name}" on ${this.formatDate(item.lastexport)}`,
                               color: 'success'})
        })
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    created () {
      this.initialize()
    }
  };
  </script>
  
  
  <style scoped>
  </style>
<template>
    <v-container fluid>
      <v-card class="ml-n3">
        <v-card-title>Export Data</v-card-title>
        <v-card-text>
          <v-select
            v-model="select"
            :items="items"
            item-text="country"
            item-value="code"
            label="Select"
            persistent-hint
            return-object
            single-line
          />
        </v-card-text>
        <v-card-actions>
          <span v-if="loading">Your file is being prepared</span>
          <v-btn @click=exportData()>
            <span v-if="!loading">Export</span>
            <v-progress-circular indeterminate v-else /></v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import { get } from "vuex-pathify";
    
  import ExportFileService from '@/services/ExportFileService'  
  
  export default {
    name: "AdminExportData",
    data() {
      return {
        loading: false,
        select: "",
        items: [
          {country: "Albania", code: "al"},
          {country: "Bosnia", code: "ba"},
          {country: "Bulgaria", code: "bg"},
          {country: "Croatia", code: "hr"},
          {country: "Greece", code: "el"},
          {country: "North Macedonia", code: "mk"},
          {country: "Serbia", code: "rs"},
          {country: "ALL COUNTRIES", code: "all"}
        ]
      };
    },
    computed: {
        ...get('newauth', [
          'userProfile@country',
          'userProfile@is_superuser'
        ]),
    },
    methods: {
      async exportData () {
        if (this.select.code === "all") {
          this.loading = true
          ExportFileService.exportAll(this.$store.state.newauth.token).then((res) => {
            var FILE = window.URL.createObjectURL(
              new Blob([res.data],
              { type: 'application/vnd.ms-excel'}
            ));
            this.loading = false;
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', `export-${this.select.country}-${new Date().toLocaleDateString()}.xlsx`);
            document.body.appendChild(docUrl);
            docUrl.click();
          }).catch((error) => {
            console.log(error)
            this.loading=false
          })
        } else {
          this.loading = true
          ExportFileService.exportByCountry(this.select.code, this.$store.state.newauth.token).then((res) => {
            var FILE = window.URL.createObjectURL(
              new Blob([res.data],
              { type: 'application/vnd.ms-excel'}
            ));
            this.loading = false;
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', `export-${this.select.country}-${new Date().toLocaleDateString()}.xlsx`);
            document.body.appendChild(docUrl);
            docUrl.click();
          }).catch((error) => {
            console.log(error)
            this.loading = false
          })
        }
      }
    },
  };
  </script>
  
  
  <style scoped>
  </style>